@import 'styles/utils/_variables.scss';
@import 'styles/utils/_mixins.scss';


.signup-modal {
    background-color: #fff;
    padding: 25px;

    h3 {
        margin-top: 20px;
        font-size: 36px;
        font-weight: $font-bold;
        color: $color-brand-1;
        text-align: center;
    }

    p {
        font-size: 14px;
        text-align: center;
    }

    .btn {
        width: 100%;
        height: 53px;
        line-height: 53px;
        padding: 0;
        font-weight: 500;

        &:last-child {
            margin-top: 10px;
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 35px 0 20px 0;
    }

    &__option {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(50% - 10px);
        border-radius: 3px;
        border: 1px solid #E7EDF0;
        transition: 0.25s;
        padding: 20px;
        cursor: pointer;

        img {
            width: 76px;
            margin-bottom: 7px;
        }

        span {
            color: #818EA8;
            font-size: 12px;
        }

        p {
            margin: 0;
            font-size: 22px;
            line-height: 20px;
            color: #818EA8;
            font-weight: $font-bold;
        }

        &.selected {
            box-shadow: 0 4px 10px rgba(107, 142, 61, 0.1);
            border: 1px solid #8CC63E;

            p {
                color: #8CC63E;
            }
        }
    }
}
