.form {
    &-label {
        font-size: 24px;
        font-weight: 500;
        color: $color-brand-1;
        position: absolute;
        top: 0;
        transition: 0.5s;
        z-index: -1;

        span {
            color: lighten($color-brand-1, 30%);
        }
    }

    &-group {
        margin: 0 0 25px 0;
        position: relative;
        z-index: 0;
    }

    &-control {
        height: 45px;
        line-height: 45px;
        margin: 0;
        background-color: transparent !important;
        border-left: 0;
        border-right: 0;
        border-top: 0;
        border-radius: 0;
        border-bottom: 2px solid $color-brand-1 !important;
        box-shadow: none;
        outline: none;
        transition: 0.5s;
        z-index: 100;

        &:focus + label,
        &:valid + label {
            transform: translateY(-15px);
            font-size: 15px;
        }

        &:-webkit-autofill,
        &-internal-autofill-selected {
            box-shadow: 0 0 0 1000px #f6f6f6 inset !important;
        }
    }

    select {
        background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=) no-repeat 100% 50%;
        appearance: none;
    }

    &-group-c {
        width: 100%;
        margin: 0 0 25px 0;
        position: relative;
        z-index: 0;

        label {
            display: block;
            font-size: 12px;
            font-weight: $font-bold;
        }
    }

    &-control-c {
        width: 100%;
        height: 55px;
        line-height: 55px;
        margin: 0;
        background-color: #fff;
        border: 1px solid #EAEEF5;
        box-shadow: none;
        outline: none;
        border-radius: 3px;
        padding: 0 20px;
        transition: 0.5s;
        z-index: 100;

        &:focus + label,
        &:valid + label {
            transform: translateY(-15px);
            font-size: 15px;
        }

        &:-webkit-autofill,
        &-internal-autofill-selected {
            box-shadow: 0 0 0 1000px #f6f6f6 inset !important;
        }
    }
}
