/* stylelint-disable selector-max-id, property-no-vendor-prefix */


.no-transition,
.no-transition * {
    transition-property: none !important;
    transform: none !important;
    animation: none !important;
}

body {
    overflow-x: hidden;
}

main {
    // padding: 0 0 $spacing-double;
    height: 100%;
    overflow: auto;

    &.error-page {
        padding-top: $spacing-double;
    }
}

.loading-container {
    margin-top: $spacing-double;
}

img {
    max-width: 100%;
    height: auto;
}

a,
button {
    &::-moz-focus-inner {
        border: 0;
    }

    &:focus {
        outline: 0;
    }
}

hr {
    border-color: #ccc;
}

section {
    figure {
        margin-bottom: $spacing;

        figcaption {
            display: block;
            margin-bottom: $spacing-half;
        }

        img {
            width: 100%;
        }
    }
}

article section {
    margin-bottom: $spacing-double;
}

.form-control {
    font-family: $font-family-1;
    box-shadow: none !important;

    // needed to fix disappearing text in input fields with "-webkit-overflow-scrolling: touch" enabled
    -webkit-transform: translate3d(0, 0, 0);
}

.input-group {
    > .input-group-prepend,
    > .input-group-append {
        > .btn {
            border: 1px solid #ced4da;
        }
    }

    &.-transparent {
        > .input-group-prepend > *:first-child {
            border-radius: 0.25rem 0 0 0.25rem;
        }

        .form-control {
            order: 10;

            &:focus + .input-group-append > * {
                border-color: $color-input-focus-border;
            }
        }

        &.-prepend .form-control {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            &:focus + .input-group-prepend > * {
                border-color: $color-input-focus-border;
            }
        }
    }
}

.tooltip {
    z-index: $zindex-dropdown + 10;
}

.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
    transition: opacity 300ms ease-out;
}

.container {
    width: 1200px;
    max-width: calc(100% - 40px);
    margin: 0 auto;
}

.modal-backdrop {
    background-color: #000 !important;

    &.show {
        opacity: 0.3 !important;
    }
}

.modal-dialog {
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%) !important;
    width: 400px;
    padding: 0;
    max-width: 100%;
}

.modal-body {
    padding: 0;
}

.hidden {
    display: none !important;
}

.modal-content {
    position: relative;
    border: 0;
    padding: 0;
    border-radius: 3px;
    background-color: #F9FAFD;
}

.modal-close {
    position: absolute;
    top: 15px;
    right: 5px;
    cursor: pointer;

    span {
        display: block;
        width: 25px;
        height: 3px;
        border-radius: 3px;
        background-color: #000;
        transition: 0.5s;

        &:first-child {
            transform: rotate(45deg) translate(2px, 2px);
        }

        &:last-child {
            transform: rotate(135deg);
        }
    }

    &:hover {
        span {
            background-color: $color-brand-1;
        }
    }
}

input:-internal-autofill-selected {
    background-color: lighten($color-brand-1, 60%) !important;
}

.page-with-header {
    padding-top: 110px;
}


/* stylelint-enable */
