@import 'styles/utils/_variables.scss';
@import 'styles/utils/_mixins.scss';


.processing {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0.5;
    }

    &__wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: translateX(-50%) translateY(-50%);
    }
}
