.checkbox,
.checkbox-unique,
.checkbox-inline {
    position: relative !important;

    label {
        position: relative !important;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        margin: 0;
        color: #666;
        font-weight: 500;
    }

    label::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid #e1e1e1;
        border-radius: 3px;
        background: #fff;
    }

    label::after {
        content: '✓';
        width: 18px;
        height: 18px;
        text-align: center;
        color: #fff;
        font-weight: $font-bold;
        line-height: 18px;
        background: $color-brand-1;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 3px;
        transition: all 0.2s ease;
    }

    input[type="checkbox"]:checked,
    input[type="checkbox"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    input[type="checkbox"]:not(:checked) + label::after {
        opacity: 0;
        transform: scale(0);
    }

    input[type="checkbox"]:checked + label::after {
        opacity: 1;
        transform: scale(1);
    }
}
