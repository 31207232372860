@import 'styles/utils/_variables.scss';
@import 'styles/utils/_mixins.scss';


.cmodal {
    background-color: #fff;
    padding: 25px;

    h3 {
        margin-top: 20px;
        font-size: 36px;
        font-weight: $font-bold;
        color: $color-brand-1;
        text-align: center;
    }

    p {
        font-size: 14px;
        text-align: center;
    }

    .btn {
        width: 100%;
        height: 53px;
        line-height: 53px;
        padding: 0;
        font-weight: 500;

        &:last-child {
            margin-top: 10px;
        }
    }

    &__top-img {
        width: 120px;
        margin: 0 auto;
        display: block;
        margin-top: 30px;
    }

    .with-label {
        position: relative;

        .form-control-c {
            padding-right: 0;
            padding-left: 60px;
        }

        span {
            position: absolute;
            left: 15px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 16px;
            font-weight: bold;
            color: #B5BBC7;
        }
    }

    .auction-duration-form {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &__type {
            width: 110px;
            height: 55px;
            line-height: 55px;
            margin: 0;
            background-color: #fff;
            border: 1.5px solid #EAEEF5;
            box-shadow: none;
            border-radius: 3px;
            font-size: 17px;
            padding: 0;
            text-align: center;
            transition: 0.5s;
            cursor: pointer;

            &.selected,
            &:hover {
                border: 1.5px solid $color-brand-1;
            }
        }

        .form-group-c {
            width: calc(100% - 135px);
        }

        input {
            padding-right: 0;
            padding-left: 5px;
        }
    }


    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 35px 0 20px 0;
    }

    &__option {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(50% - 10px);
        border-radius: 3px;
        border: 1px solid #E7EDF0;
        transition: 0.25s;
        padding: 20px;
        cursor: pointer;

        img {
            width: 76px;
            margin-bottom: 7px;
        }

        span {
            color: #818EA8;
            font-size: 12px;
        }

        p {
            margin: 0;
            font-size: 22px;
            line-height: 20px;
            color: #818EA8;
            font-weight: $font-bold;
        }

        &:hover {
            box-shadow: 0 4px 10px rgba(107, 142, 61, 0.1);
            border: 1px solid #8CC63E;

            p {
                color: #8CC63E;
            }
        }
    }
}

.edit-address {
    width: 650px;

    form {
        margin-top: 30px;
    }

    &__map {
        width: 100%;
        height: 300px;
        margin: 10px 0 30px 0;
    }

    &__half-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        .form-group-c {
            width: calc(50% - 10px) !important;
        }
    }

    @include screen-sm() {
        .form-group-c {
            width: 100% !important;
        }
    }
}
