@import 'styles/utils/_variables.scss';
@import 'styles/utils/_mixins.scss';


.search-input {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__img {
        width: 18px !important;
        height: 18px !important;
        margin: 0 0 0 12px !important;
    }

    button {
        background-color: $color-brand-1;
        border: 0;
        height: 100%;
        line-height: 100%;
        color: #fff;
        border-radius: 0 5px 5px 0;
        width: 115px;
        outline: none !important;
    }

    .ssl {
        &__container {
            width: 180px;
            height: 100%;
            border-left: 1px solid #DFE6F4;
            padding-left: 15px;
        }

        &__control {
            width: 165px;
            height: 100%;
            border: 0 !important;
            border-radius: 0;
            box-shadow: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__value-container {
            padding: 0;
            height: 100%;
        }

        &__indicator-separator {
            display: none;
        }

        &__placeholder,
        &__single-value {
            transform: none;
            top: 0;
            height: 100%;
            line-height: 100%;
            color: #272727;
            font-weight: $font-bold;

            &::before {
                content: "";
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }
        }

        &__option--is-focused {
            background-color: lighten($color-brand-1, 60%);
        }

        &__option--is-selected {
            background-color: $color-brand-1;
        }

        &__input {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    input {
        width: calc(100% - 325px);
        height: 100%;
        line-height: 100%;
        border: 0;
        outline: 0;
        margin-left: 15px;
        font-size: 16px;
        font-weight: 500;

        &::placeholder {
            color: #B5BBC7;
        }
    }
}
