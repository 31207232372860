@import 'styles/utils/_variables.scss';
@import 'styles/utils/_mixins.scss';


$offset: 187;
$duration: 1.4s;

.spinner {
    animation: rotator $duration linear infinite;
    vertical-align: middle;

    .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        stroke: $color-brand-1;
        animation: dash $duration ease-in-out infinite;
    }
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(270deg);
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: $offset;
    }

    50% {
        stroke-dashoffset: $offset/4;
        transform: rotate(135deg);
    }

    100% {
        stroke-dashoffset: $offset;
        transform: rotate(450deg);
    }
}
