@import 'styles/utils/_variables.scss';
@import 'styles/utils/_mixins.scss';


.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: visible;
    z-index: 100;
    background-color: #550076;
    transition: 0.5s;


    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    &__logo {
        width: 200px;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .btn {
            margin-left: 7px;
        }
    }

    &__search {
        height: 47px;
        width: 770px;
        max-width: calc(100% - 360px) !important;
    }

    &__icon {
        color: #fff;
        font-size: 30px;
    }

    &__icons {
        display: none;
        width: 770px;
        max-width: calc(100% - 360px) !important;
        align-items: center;
        justify-content: flex-end;
        padding: 0 20px;
    }

    .btn {
        height: 47px;
    }

    &.is-scrolled {
        height: 70px;
    }

    @include screen-md() {
        &__icons {
            display: flex;
        }

        &__search {
            display: none;
            justify-self: flex-end;

            &.expanded {
                display: block !important;
                width: 100% !important;
                max-width: 100% !important;
                position: absolute;
                top: 100%;
                left: 0;

                .search-input {
                    border-radius: 0;
                }

                button {
                    border-radius: 0;
                }
            }
        }
    }

    @include screen-xs() {
        &__logo {
            width: 120px;
        }

        &__icon {
            font-size: 22px;
        }

        &__icons {
            padding: 0 15px;
            max-width: calc(100% - 250px) !important;
        }

        .btn {
            font-size: 12px;
        }
    }
}

.header-user {
    position: relative;
    z-index: 1000;

    &__avatar {
        width: 42px;
        height: 42px;
        background-color: #fff;
        border-radius: 50%;
        border: 2px solid #fff;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    &__list {
        width: 175px;
        display: none;
        position: absolute;
        background-color: #fff;
        right: 0;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        top: calc(100% + 10px);

        &.opened {
            display: block;
        }
    }

    &__item {
        display: block;
        font-size: 16px;
        padding: 10px 15px;
        font-weight: 700;
        text-decoration: none !important;

        &.top-border {
            border-top: 2px solid lighten($color-brand-1, 60%);
        }

        &.red-labeled {
            color: #c00;
        }
    }
}

.header-not {
    position: relative;
    z-index: 1000;

    &__avatar {
        width: 20px;
        height: 20px;
        margin-right: 15px;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__box {
        display: none;
        width: 330px;
        position: absolute;
        background-color: #fff;
        right: 0;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        top: calc(100% + 10px);

        &.opened {
            display: block;
        }

        &-top {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px;
            border-bottom: 1px solid #DFE6F4;

            h3 {
                font-size: 14px;
                margin: 0;
            }
        }

        &-link {
            color: #248CEC;
            font-weight: bold;
            font-size: 13px;
            cursor: pointer;
        }
    }

    &__footer {
        width: 100%;
        text-align: center;
        color: #248CEC;
        font-weight: bold;
        font-size: 13px;
        cursor: pointer;
        padding: 10px 15px;
        border-top: 1px solid #DFE6F4;
    }

    &__count {
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        background-color: #c00;
        color: #fff;
        font-size: 10px;
        border-radius: 50%;
        position: absolute;
        top: -10px;
        left: -10px;
    }

    &__list {
        width: 100%;
    }

    &__error {
        padding: 20px;
        color: #999;
        font-weight: bold;
        margin: 0;
        text-align: center;
    }

    &__item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-left: 4px solid transparent;
        padding: 20px;
        border-bottom: 1px solid #F5F9FF;
        cursor: pointer;

        img {
            width: 20px;
            margin-right: 10px;
        }

        &.unread {
            border-left: 4px solid $color-brand-1;
            background-color: rgba(127, 9, 173, 0.05);
        }
    }

    &__info {
        width: calc(100% - 90px);

        h2 {
            font-size: 14px;
            font-weight: bold;
            color: $color-brand-1;
            margin-bottom: 0;
        }

        p {
            font-size: 12px;
            margin: 0;
        }

        span {
            color: $color-brand-1;
        }
    }

    &__time {
        text-align: right;
        width: 60px;
        color: #B6C2D8;
        font-weight: bold;
        font-size: 12px;
    }
}
